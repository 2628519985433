import * as React from 'react'

import CopyLinkIcon from 'asset/icon/copylink_button.svg'
import FacebookIcon from 'asset/icon/facebook_button.svg'
import MessengerIcon from 'asset/icon/messenger_button.svg'
import TwitterAppIcon from 'asset/icon/twitter_button.svg'
import WhatsAppIcon from 'asset/icon/whatsapp_button.svg'
import {
  BASE_URL,
  COPY_LINK,
  FACEBOOK,
  GA_CLICK_ACTION,
  TWITTER,
  WHATS_APP
} from 'constant'
import type { ShareAction, ShareType } from './types'

export const getShareContent = (type: ShareType, id: string): string => {
  switch (type) {
    case 'hashtag':
      return new URL('/hashtag/' + id, BASE_URL).toString()
    case 'profile':
      return new URL(id, BASE_URL).toString()
    case 'spot':
      return new URL('/spot/' + id, BASE_URL).toString()
    case 'community':
      return new URL('/community/' + id, BASE_URL).toString()
    case 'place':
      return new URL('/place/' + id, BASE_URL).toString()
  }
}

export const getLink = (
  type: ShareAction,
  shareContent: string,
  isMobile: boolean
) => {
  switch (type) {
    case FACEBOOK:
      return `https://www.facebook.com/sharer/sharer.php?u=${shareContent}`
    // case MESSENGER:
    //   return !isMobile
    //     ? `https://www.facebook.com/dialog/send?link=${shareContent}&app_id=${process.env.FACEBOOK_CLIENT_ID}&redirect_uri=${shareContent}`
    //     : `fb-messenger://share/?link=${shareContent}&app_id=${process.env.FACEBOOK_CLIENT_ID}`
    case TWITTER:
      return `https://twitter.com/intent/tweet?url=${shareContent}`
    case WHATS_APP:
      return `https://api.whatsapp.com/send/?text=${shareContent}`
    default:
      return ''
  }
}

export const getIcon = (type: ShareAction) => {
  switch (type) {
    case FACEBOOK:
      return FacebookIcon
    // case MESSENGER:
    //   return MessengerIcon
    case TWITTER:
      return TwitterAppIcon
    case WHATS_APP:
      return WhatsAppIcon
    case COPY_LINK:
      return CopyLinkIcon
    default:
      return React.Fragment
  }
}

export const getGaAction = (type: ShareAction) => {
  switch (type) {
    case FACEBOOK:
      return GA_CLICK_ACTION.Share.shareFacebookClickAction
    // case MESSENGER:
    //   return GA_CLICK_ACTION.Share.shareMessengerClickAction
    case TWITTER:
      return GA_CLICK_ACTION.Share.shareTwitterClickAction
    case WHATS_APP:
      return GA_CLICK_ACTION.Share.shareWhatsAppClickAction
    case COPY_LINK:
      return GA_CLICK_ACTION.Share.shareCopyLinkClickAction
    default:
      return ''
  }
}
