import * as RUIDialog from '@radix-ui/react-dialog'
import { useTranslations } from 'next-intl'
import { useCallback, useState, type PropsWithChildren } from 'react'
import toast from 'react-hot-toast'

import { DialogClose, DialogTitle } from 'component/ui/dialog'
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger
} from 'component/ui/popover'
import { COPY_LINK, TWITTER, WHATS_APP } from 'constant'
import { usePopupStore } from 'store/global/popup'
import { useDeviceStore } from 'store/server-context/device'
import ShareButton from './button'
import { ButtonsBox, ShareDialogContent, ShareMask } from './popup.style'
import type { ShareAction, ShareType } from './types'
import { getShareContent } from './utils'

const shareData: Record<ShareType, readonly ShareAction[]> = {
  hashtag: [WHATS_APP, COPY_LINK, TWITTER],
  profile: [WHATS_APP, COPY_LINK, TWITTER],
  spot: [WHATS_APP, COPY_LINK, TWITTER],
  community: [WHATS_APP, COPY_LINK, TWITTER],
  place: [WHATS_APP, COPY_LINK, TWITTER]
}

const getOffset = (type: ShareType) => {
  if (type === 'spot') {
    return {
      mainAxis: 20,
      crossAxis: 0
    }
  }
  return {
    mainAxis: 10,
    crossAxis: 0
  }
}

const gridStyle = {
  gridTemplateRows: '8px 22px 28px 1fr 24px 34px',
  gridTemplateAreas: "'.' 'title' '.' 'buttons' '.' 'cancel'"
}

interface IShare {
  type: ShareType
  shareId: string
  onOpen?: () => void
  onClose?: () => void
}
export const SharePopup = (props: PropsWithChildren<IShare>) => {
  const { type, shareId, onOpen, onClose, children } = props

  const t = useTranslations()
  const [isOpened, setIsOpened] = useState(false)
  const showSharePopup = usePopupStore(state => state.showSharePopup)
  const hideSharePopup = usePopupStore(state => state.hideSharePopup)
  const showDownloadPopup = usePopupStore(state => state.showDownloadPopup)
  const hideDownloadPopup = usePopupStore(state => state.hideDownloadPopup)
  const device = useDeviceStore(state => state.device)
  const isMobile = device === 'mobile'
  const offset = getOffset(type)

  const outsidePress: RUIDialog.DismissableLayerProps['onPointerDownOutside'] =
    event => {
      let target = event.target as HTMLElement
      if (target.id === 'cookie-container') {
        event.preventDefault()
        return
      }
      let found = false
      while (!found && target.parentElement) {
        found = target.id === 'cookie-container'
        target = target.parentElement
      }

      if (found) {
        event.preventDefault()
      }
    }

  const handleHintAnimation = (text: string): void => {
    toast(text, { duration: 3000 })
  }

  const closeShareModal = useCallback((): void => {
    hideSharePopup()
    setIsOpened(false)
    device !== 'desktop' && showDownloadPopup()
    onClose?.()
  }, [hideSharePopup, device, showDownloadPopup, onClose])

  const handleClose = useCallback(() => {
    closeShareModal()
  }, [closeShareModal])

  const openShareModal = useCallback((): void => {
    showSharePopup()
    setIsOpened(true)
    hideDownloadPopup()
    onOpen?.()
  }, [hideDownloadPopup, onOpen, showSharePopup])

  const handleOpenChange = (isOpened: boolean): void => {
    function openFallback() {
      if (isOpened) {
        openShareModal()
      } else {
        handleClose()
      }
    }
    if (device !== 'desktop' && 'share' in navigator) {
      onOpen?.()
      hideDownloadPopup()
      navigator
        .share({
          url: getShareContent(type, shareId)
        })
        .then(() => {})
        .catch(error => {})
        .finally(() => {
          onClose?.()
          showDownloadPopup()
        })
      return
    }

    openFallback()
  }

  if (isMobile) {
    return (
      <RUIDialog.Root open={isOpened} onOpenChange={handleOpenChange}>
        <RUIDialog.Trigger asChild={true}>{children}</RUIDialog.Trigger>
        <RUIDialog.Portal>
          <ShareMask />
          <ShareDialogContent
            data-testid='share-container'
            onPointerDownOutside={outsidePress}
            style={gridStyle}
          >
            <DialogTitle className='text-center text-lg font-semibold text-label-l1 [grid-area:title]'>
              {t('share.share_to')}
            </DialogTitle>
            <ButtonsBox className='[grid-area:buttons]'>
              {shareData[type].map((item, i, _self) => {
                return (
                  <ShareButton
                    key={item}
                    type={type}
                    shareId={shareId}
                    shareAction={item}
                    divider={i + 1 !== _self.length}
                    onClose={handleClose}
                    handleHintAnimation={handleHintAnimation}
                  />
                )
              })}
            </ButtonsBox>
            <DialogClose
              className='flex h-[48px] cursor-pointer items-center justify-center text-md text-label-l2 [grid-area:cancel]'
              onClick={handleClose}
              data-testid='share-button-cancel'
            >
              {t('common.cancel')}
            </DialogClose>
          </ShareDialogContent>
        </RUIDialog.Portal>
      </RUIDialog.Root>
    )
  }

  return (
    <Popover open={isOpened} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild={true}>{children}</PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          data-testid='share-container'
          side={type === 'spot' ? 'right' : 'bottom'}
          align='end'
          sideOffset={offset.mainAxis}
          alignOffset={offset.crossAxis}
          className={`
            isolate z-popover flex w-[280px] max-w-[280px] flex-col overflow-hidden rounded-[12px] bg-floating
            drop-shadow-image transition-opacity duration-300 will-change-[opacity] scrollbar-none
          `}
        >
          <div className='cursor-pointer scrollbar-none'>
            {shareData[type].map((item, i, _self) => {
              return (
                <ShareButton
                  key={item}
                  type={type}
                  shareId={shareId}
                  shareAction={item}
                  divider={i + 1 !== _self.length}
                  onClose={handleClose}
                  handleHintAnimation={handleHintAnimation}
                />
              )
            })}
          </div>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  )
}
