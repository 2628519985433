import * as RUIDialog from '@radix-ui/react-dialog'

import { twc } from 'util/cn'

export const ShareMask = twc(
  RUIDialog.Overlay
)`fixed top-0 right-0 bottom-[--cookie-hint-height] left-0 bg-overlay-light z-popover-1
data-[state=closed]:animate-fade-out data-[state=open]:animate-fade-in`
ShareMask.displayName = 'ShareMask'

export const ShareDialogContent = twc(
  RUIDialog.Content
)`fixed bottom-0 left-0 right-0 z-popover grid will-change-transform py-[16px] w-full rounded-t-[24px] bg-floating drop-shadow-image duration-200
data-[state=closed]:animate-share-hide data-[state=open]:animate-share-show`
ShareDialogContent.displayName = 'ShareDialogContent'

export const ButtonsBox = twc.div`flex overflow-x-auto scrollbar-none mx-auto`
ButtonsBox.displayName = 'ButtonsBox'
