import { useTranslations } from 'next-intl'
import * as React from 'react'

import { COPY_LINK, FACEBOOK, MESSENGER, TWITTER, WHATS_APP } from 'constant'
import { useIsMobile } from 'store/server-context/device'
import { Anchor, Divider, Icon, Text } from './button.style'
import type { ShareAction, ShareType } from './types'
import { getGaAction, getIcon, getLink, getShareContent } from './utils'

interface IShareButton {
  type: ShareType
  shareAction: ShareAction
  shareId: string
  divider: boolean
  onClick?: (action: ShareAction) => void
  onClose: () => void
  handleHintAnimation: (text: string) => void
}
const ShareButton = (props: IShareButton) => {
  const {
    type,
    shareAction,
    shareId,
    divider,
    onClick,
    onClose,
    handleHintAnimation
  } = props
  const t = useTranslations()

  const isMobile = useIsMobile()
  const shareText: Record<ShareAction, string> = isMobile
    ? {
        [FACEBOOK]: t('common.facebook'),
        // [MESSENGER]: t('common.messenger'),
        [TWITTER]: t('common.twitter'),
        [WHATS_APP]: t('common.whatsapp'),
        [COPY_LINK]: t('share.copy_link')
      }
    : {
        [FACEBOOK]: t('share.share_to_facebook'),
        // [MESSENGER]: t('share.share_to_messenger'),
        [TWITTER]: t('share.share_to_twitter'),
        [WHATS_APP]: t('share.share_to_whatsapp'),
        [COPY_LINK]: t('share.copy_link')
      }

  const shareContent = getShareContent(type, shareId)

  const handleClick = () => {
    onClick?.(shareAction)
    onClose()
  }

  const handleCopyLinkClick = () => {
    handleClick()

    if (navigator.clipboard) {
      navigator.clipboard.writeText(shareContent).then(
        () => handleHintAnimation(t('share.link_copied')),
        () => handleHintAnimation(t('share.failed_to_copy'))
      )
    } else {
      handleHintAnimation(t('share.failed_to_copy'))
    }
  }

  switch (shareAction) {
    case FACEBOOK:
    case TWITTER:
    case WHATS_APP:
      return (
        <Anchor
          href={getLink(shareAction, shareContent, isMobile)}
          onClick={handleClick}
          data-ga-click={getGaAction(shareAction)}
          $external
        >
          <Text>{shareText[shareAction]}</Text>
          <Icon asChild>{React.createElement(getIcon(shareAction))}</Icon>
          {divider && <Divider />}
        </Anchor>
      )
    case COPY_LINK:
      return (
        <Anchor asChild>
          <div
            onClick={handleCopyLinkClick}
            data-ga-click={getGaAction(COPY_LINK)}
          >
            <Text>{shareText[COPY_LINK]}</Text>
            <Icon asChild>{React.createElement(getIcon(COPY_LINK))}</Icon>
            {divider && <Divider />}
          </div>
        </Anchor>
      )
    default:
      return <></>
  }
}

export default ShareButton
