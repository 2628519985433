'use client'

import * as PopoverPrimitive from '@radix-ui/react-popover'

import { twc, type TwcComponentWithAsChild } from 'util/cn'

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverPortal = PopoverPrimitive.Portal

type PopoverContentBaseProps = TwcComponentWithAsChild<
  typeof PopoverPrimitive.Content
>
const PopoverContent = twc(
  PopoverPrimitive.Content
).attrs<PopoverContentBaseProps>(props => ({
  asChild: props.$asChild,
  align: props.align ?? 'center',
  sideOffset: props.sideOffset ?? 4
}))`z-popover outline-none
data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2
data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95
data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95`
PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { Popover, PopoverContent, PopoverPortal, PopoverTrigger }
