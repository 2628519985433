import { twc, type TwcComponentProps } from 'util/cn'

type AnchorProps = TwcComponentProps<'a'> & {
  $external?: boolean
}
export const Anchor = twc.a.attrs<AnchorProps>(
  ({ $external }) =>
    $external && {
      target: '_blank',
      rel: 'noopener noreferrer nofollow'
    }
)`relative flex h-[54px] items-center px-[12px] will-change-[background-color]
transition-colors duration-200 hover:bg-background_elevated-b1
active:bg-background_elevated-b1
playsee-mb:mx-[16px] playsee-mb:h-full playsee-mb:max-w-[48px] playsee-mb:flex-col-reverse playsee-mb:p-0 playsee-mb:hover:bg-transparent`

export const Text = twc.span`flex-auto pr-[8px] playsee-mb:whitespace-nowrap playsee-mb:p-0 playsee-mb:text-sm`
export const Divider = twc.div`absolute bottom-0 left-[12px] right-[12px] border-b border-glass_special-separators playsee-mb:hidden`
export const Icon = twc.div`sq-[24px] playsee-mb:sq-[48px]`
